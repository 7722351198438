import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SwipeableDrawer,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import {Idp, IdpManagement} from "@common/entity/";
import {InfoOutlined, Search} from "@material-ui/icons";
import {IdpManagementApiService} from "@common/api-service/lib/src/idp-management-api.service";
import * as _ from "lodash";
import baseStyles from "../../../../styles/base.module.scss";
import style from "./OwnerLinkAccount.module.scss";
import {Button as VacasaButton} from "@vacasa/react-components-lib";
import {ErrorModal} from "../../../common/ErrorModal/ErrorModal";
import {ConfirmationModal} from "../../../common/ConfirmationModal/ConfirmationModal";
import {getAppliedBy} from "../../../utils/loggedUser";

interface OwnerSearchProps {
    sidebarState: boolean;
    setSidebarState: Function;
    selectedOwner: Idp.User;
    setRefreshOwner: Function;
}

const idpManagementApiService = new IdpManagementApiService();

enum SelectState {
    None = -1,
    Keep = 1,
    Disable = 0,
}

export const OwnerLinkAccount: React.FunctionComponent<OwnerSearchProps> = ({sidebarState, setSidebarState, selectedOwner, setRefreshOwner}) => {
    const [selected, setSelected] = React.useState<string[]>([]);
    const [txtValue, setTxtValue] = React.useState<string>("");
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [searching, setSearching] = React.useState(false);
    const [rows, setRows] = React.useState<Idp.User[]>([]);
    const [state, setState] = React.useState<{}>({});
    const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
    const [masterLoginsToLink, setMasterLoginsToLink] = useState<Array<{idpUser: Idp.User; logins: Array<any>}>>([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [linkAccountToConfirm, setLinkAccountToConfirm] = useState<IdpManagement.LinkAccount>(null);

    useEffect(() => {
        if (searchValue === "" || searchValue.length < 3) {
            return undefined;
        }

        if (searchValue.length > 100) {
            setErrorMessage("Allowed only 100 characters");
            return undefined;
        }

        (async () => {
            try {
                setSearching(true);

                const usersResult = await idpManagementApiService.searchIdpOwners(searchValue?.trim());

                const enhancedUsersResult: Array<Idp.User> = usersResult.filter((x) => x.id !== selectedOwner.id);

                if (enhancedUsersResult.length > 0) {
                    setRows(enhancedUsersResult);
                    setState(_.fromPairs(enhancedUsersResult.map((x) => [x.id, SelectState.None])));
                } else {
                    setRows([]);
                    setState({});
                }
            } catch (error) {
                setRows([]);
                setState({});
                setErrorMessage("Error getting users");
            } finally {
                setSearching(false);
            }
        })();
    }, [searchValue]);

    const clearData = () => {
        setRows([]);
        setState({});
        setTxtValue("");
        setSearchValue("");
        setSelected([]);
        setErrorMessage(null);
    };

    const handleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
            return;
        }
        setSidebarState(open);
        clearData();
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleSelected = (event: ChangeEvent<{name?: string; value?: unknown}>, row: Idp.User) => {
        setState({
            ...state,
            ...{
                [row.id]: event.target.value,
            },
        });
    };

    const onConfirmLinkUser = async () => {
        try {
            setSearching(true);
            const linkResult = await idpManagementApiService.linkUsers(linkAccountToConfirm);

            if (!_.isNil(linkResult)) {
                setSidebarState(false);
                clearData();
                setRefreshOwner(+new Date());
            } else {
                throw new Error(`error linking accounts`);
            }

            setLinkAccountToConfirm(null);
            setMasterLoginsToLink([]);
        } catch (e) {
            setErrorMessage(e.message);
        } finally {
            setSearching(false);
        }
    };

    function addAppliedBy(response) {
        const linkAccount = response.data.linkAccount;

        linkAccount.applied_by = getAppliedBy();
        linkAccount.linked_users.forEach((acc) =>
            acc.applied_by = getAppliedBy()
        )
        return linkAccount;
    }

    const onLinkUser = async () => {

        try {
            setSearching(true);

            const ownerAccount: Idp.OwnerAccount = {
                id: selectedOwner.id,
                email: selectedOwner.attributes.email,
                contact_ids: selectedOwner.attributes.contact_ids,
                linked_users: [],
                state: state,
            };

            for (const s of selected) {

                let idpUser: Idp.User = rows.find((x) => x.id === s);
                ownerAccount.linked_users.push(idpUser);
            }

            const response = await idpManagementApiService.validateLinkUsers(ownerAccount);

            if (response.error) {
                setErrorMessage(response.message);
                return;
            }

            const linkAccount = addAppliedBy(response);

            if(response.data.masterLoginsToLink.length > 0) {
                setMasterLoginsToLink(response.data.masterLoginsToLink);
                setShowConfirmation(true);
                setLinkAccountToConfirm(linkAccount);
                return;
            }

            const linkResult = await idpManagementApiService.linkUsers(linkAccount);

            if (!_.isNil(linkResult)) {
                setSidebarState(false);
                clearData();
                setRefreshOwner(+new Date());
            } else {
                setErrorMessage(`error linking accounts`);
            }
        } catch (e) {
            console.log(e)
            setErrorMessage(`error linking accounts`);
        } finally {
            setSearching(false);
        }
    };

    const AccountTableBody = () => {
        if (searching) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell align="center" colSpan={6}>
                            <CircularProgress />
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        }

        if (searchValue !== "" && rows.length === 0) {
            return (
                <TableBody>
                    <TableRow>
                        <TableCell align="center" colSpan={6}>
                            <h5>No Results Found</h5>
                        </TableCell>
                    </TableRow>
                </TableBody>
            );
        }

        if (rows.length === 0) {
            return null;
        }

        return (
            <TableBody>
                {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                        <TableRow key={row.id}>
                            <TableCell id={labelId} padding="checkbox" align="left">
                                <Checkbox
                                    key={row.id}
                                    checked={isItemSelected}
                                    inputProps={{"aria-labelledby": labelId}}
                                    onClick={(event) => handleClick(event, row.id)}
                                    color={"primary"}
                                />
                            </TableCell>
                            <TableCell align="left">
                                <FormControl id="owner-link-account-component-account-form" variant="outlined" size={"small"}>
                                    <Select id="owner-link-account-component-account-select" key={row.id} value={state[row.id]} onChange={(event) => handleSelected(event, row)}>
                                        <MenuItem key={"-1" + row.id} value={SelectState.None}>
                                            <span className={baseStyles.fontLight}>select</span>
                                        </MenuItem>
                                        <MenuItem key={"1" + row.id} value={SelectState.Keep}>
                                            Keep as guest login
                                        </MenuItem>
                                        <MenuItem key={"0" + row.id} value={SelectState.Disable}>
                                            Disable login
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell align="left">{row.attributes.given_name}</TableCell>
                            <TableCell align="left">{row.attributes.family_name}</TableCell>
                            <TableCell align="left">{row.attributes.email}</TableCell>
                            <TableCell align="left">{row.attributes.legacy_user_id}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    };

    const isFormValid = () => {
        if (selected.length === 0) {
            return false;
        }

        for (const key of selected) {
            if (state[key] === SelectState.None) {
                return false;
            }
        }

        if (searching) {
            return false;
        }

        return true;
    };

    const renderEmails = (masterLoginsToLink) => {
        let emails = "";
        for (let i = 0; i < masterLoginsToLink.length; i++) {
            const masterLogin = masterLoginsToLink[i];
            if (i === masterLoginsToLink.length - 1) {
                emails += masterLogin.idpUser.attributes.email;
                continue;
            }
            if (i === masterLoginsToLink.length - 2) {
                emails += masterLogin.idpUser.attributes.email + " and ";
                continue;
            }
            emails += masterLogin.idpUser.attributes.email + ", ";
        }
        return emails;
    };

    return (
        <SwipeableDrawer anchor={"right"} open={sidebarState} onClose={handleDrawer(false)} onOpen={handleDrawer(true)}>
            <ConfirmationModal open={showConfirmation} title={"Confirmation"} onConfirm={onConfirmLinkUser} setOpen={setShowConfirmation}>
                <div>
                    {masterLoginsToLink.length > 0 && (
                        <div>
                            There are a parent accounts (master). Before it can be associated to another account, all child accounts must be unlinked
                            from it and they will return to its original single login status. Would you like to proceed and assign{" "}
                            {renderEmails(masterLoginsToLink)} to {selectedOwner.attributes.email} and unlink all accounts associated to{" "}
                            {renderEmails(masterLoginsToLink)}?
                        </div>
                    )}
                </div>
            </ConfirmationModal>
            <ErrorModal title={"Error"} setBody={setErrorMessage} body={errorMessage} />
            <div className={style.innerMarginWithBorder}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h5 className={baseStyles.titleColor} style={{marginTop: "15px", marginLeft: "15px"}}>
                            Search User
                        </h5>
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <InputLabel htmlFor="txt-users-results" variant="outlined">
                                    <span className={baseStyles.titleColor}>Search</span>
                                </InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="txt-users-results"
                                    fullWidth
                                    margin="none"
                                    variant="outlined"
                                    size="small"
                                    value={txtValue}
                                    onChange={(event) => {
                                        setTxtValue(event.target.value);
                                    }}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            setSearchValue(event.target["value"]);
                                            event.preventDefault();
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <Search
                                                style={{cursor: "pointer"}}
                                                onClick={(param) => {
                                                    setSearchValue(txtValue);
                                                }}
                                            />
                                        ),
                                    }}
                                    helperText={
                                        <span>
                                            <InfoOutlined /> &nbsp;
                                            <span>
                                                You can search by email, first name or last name. You must enter at least 3 letters to search.
                                            </span>
                                        </span>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={10}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Link</TableCell>
                                                <TableCell align="left">Setting</TableCell>
                                                <TableCell align="left">First Name</TableCell>
                                                <TableCell align="left">Last Name</TableCell>
                                                <TableCell align="left">Email</TableCell>
                                                <TableCell align="left">Legacy User Id</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <AccountTableBody />
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "right"}}>
                                <VacasaButton
                                    variant="info"
                                    onClick={(event) => onLinkUser()}
                                    disabled={!isFormValid()}
                                    customClass={style.buttonMargin}
                                >
                                    Link Login
                                </VacasaButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
            </div>
        </SwipeableDrawer>
    );
};

