import React, {useState} from "react";
import {OwnerSearch} from "./OwnerSearch/OwnerSearch";
import {OwnerInfo} from "./OwnerInfo/OwnerInfo";
import {Idp} from "@common/entity/lib/src/idp.entity";
import {OwnerLinkAccount} from "./OwnerLinkAccount/OwnerLinkAccount";
import baseStyles from "../../../styles/base.module.scss";
import styles from "./OwnerHome.module.scss";
import {OwnerChangeLog} from "./OwnerChangeLog/OwnerChangeLog";

export const OwnerHome: React.FunctionComponent<any> = () => {
    const [selectedOwner, setSelectedOwner] = useState<Idp.User>(null);
    const [sidebarState, setSidebarState] = React.useState(false);
    const [refreshOwner, setRefreshOwner] = useState<number>(0);

    return (
        <React.Fragment>
            <h3 className={baseStyles.titleColor}>Owner Master Login</h3>
            <br />
            <div className={baseStyles.mainBackgroundColor}>
                <OwnerSearch setSelectedOwner={setSelectedOwner} setRefreshOwner={setRefreshOwner} selectedOwner={selectedOwner}/>
                <br />

                {selectedOwner != null ? (
                    <React.Fragment>
                        <div className={styles.ownerInfoMargin}>
                            <OwnerInfo
                                selectedOwner={selectedOwner}
                                setSelectedOwner={setSelectedOwner}
                                setSidebarState={setSidebarState}
                                refreshOwner={refreshOwner}
                                setRefreshOwner={setRefreshOwner}
                            />
                        </div>
                        <br />
                        <div className={styles.ownerInfoMargin}>
                            <OwnerChangeLog
                                selectedOwner={selectedOwner}
                                refreshOwner={refreshOwner}
                            />
                        </div>
                        <br />
                    </React.Fragment>
                ) : null}
                <OwnerLinkAccount
                    selectedOwner={selectedOwner}
                    sidebarState={sidebarState}
                    setSidebarState={setSidebarState}
                    setRefreshOwner={setRefreshOwner}
                />
            </div>
        </React.Fragment>
    );
};
