import React, { createRef } from "react";
import {
    Grid,
    TextField,
    InputLabel,
    makeStyles,
    Button,
    Box, Input,
} from "@material-ui/core";
import { Idp } from "@common/entity";
import { OwnerAlert } from "../OwnerAlert/OwnerAlert";
import baseStyles from "../../../../styles/base.module.scss";
import { IdpManagementApiService } from "@common/api-service";
import {OwnerUsersTable} from "../OwnerUsersTable/OwnerUsersTable";

interface OwnerSearchProps {
    setSelectedOwner: Function;
    setRefreshOwner: Function;
    selectedOwner: Function;
}

const idpManagementApiService = new IdpManagementApiService();

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inputRoot: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#008c96",
        },
    },
}));

export const OwnerSearch: React.FunctionComponent<OwnerSearchProps> = ({setSelectedOwner, setRefreshOwner, selectedOwner}) => {
    const classes = useStyles();

    const [options, setOptions] = React.useState<Idp.User[]>([]);
    const [searching, setSearching] = React.useState(false);

    const searchRef: React.RefObject<any> = createRef()

    const onSearchClick = async () => {
        const value = searchRef.current.value;

        if (!value.length) {
            return undefined;
        }

        if (value.length > 100) {
            setOptions([]);
            alert("allowed only 100 characters");
            return undefined;
        }

        setSearching(true);
        setSelectedOwner(null)
        const usersResult = await idpManagementApiService.searchIdpOwners(value?.trim());
        setOptions(usersResult);
        setSearching(false);
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <InputLabel htmlFor="txt-users-results" variant="outlined">
                                <h5 className={baseStyles.titleColor}>Search Owner</h5>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="txt-users-results"
                                fullWidth
                                margin="none"
                                variant="outlined"
                                size="small"
                                helperText="* Only up to 100 results are displayed, refine your search criteria and press Search Now again."
                                placeholder="Search by email, first name or last name"
                                style={{marginTop: "12px"}}
                                disabled={searching}
                                inputRef={searchRef}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        onSearchClick()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}
                          style={{
                                paddingRight: "23px",
                            }}
                        >
                            <Button
                                disabled={searching}
                                size="medium"
                                fullWidth={true}
                                style={{
                                    textTransform: "none",
                                    height:"40px",
                                    marginTop: "12px",
                                    color: "white",
                                    fontWeight: "bold",
                                    backgroundColor: "#1B78AB",
                                    boxShadow: "none",
                                }}
                                variant="contained"
                                onClick={onSearchClick}
                            >
                                {
                                    searching ? (
                                        "Searching..."
                                    ) : (
                                        "Search Now"
                                    )
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {
                selectedOwner === null ? (
                    <Grid item xs={12} zeroMinWidth>
                        <Box className={baseStyles.tableMargin}>
                            <OwnerUsersTable
                                options={options}
                                setOptions={setOptions}
                                setSelectedOwner={setSelectedOwner}
                                setRefreshOwner={setRefreshOwner}
                                searching={searching}
                            />
                        </Box>
                    </Grid>
                ) : null
            }
        </div>
    );
};
