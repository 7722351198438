import React from "react";
import styles from "./OwnerSingleLoginApp.module.scss";
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import {OwnerHome} from "./OwnerHome/OwnerHome";

export const OwnerSingleLoginApp: React.FunctionComponent<any> = (props) => {
    return (
        <div className={styles.innerMargin}>
            <Router>
                <Switch>
                    <Route path="/">
                        <OwnerHome />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
};
