import React, {useEffect} from "react";
import { ZoomIn } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {
    CircularProgress,
    Grid, makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from "@material-ui/core";
import { TableActions } from "../../../common/Table/TableActions";

const useStyles = makeStyles((theme) => ({
    containerTableRoot: {
        "&.MuiTableCell-root": {
            padding: "20px",
        },
        "&.MuiTableContainer-root": {
            border: "0 !important",
            padding: "20px",
        },
        "&.MuiTable-root": {
            border: "0 !important",
            padding: "20px",
        },
    },
}));

export const OwnerUsersTable = ({ options, setOptions, setSelectedOwner, setRefreshOwner, searching }) => {
    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - options.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onClickDetails = (id: string) => {
        const selectedUser = options.find((option) => option.id === id)
        setOptions(selectedUser);
        setSelectedOwner(selectedUser);
        setRefreshOwner(+new Date());
    }

    useEffect(() => {
        setPage(0);
    }, [searching])

    return (
        <TableContainer className={classes.containerTableRoot} style={{border: "0"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Name</strong></TableCell>
                            <TableCell align="left"><strong>Email</strong></TableCell>
                            <TableCell align="right"><strong>View Details</strong></TableCell>
                        </TableRow>
                    </TableHead>

                    {
                        searching ? (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {
                                    options.length ?
                                        (
                                            (rowsPerPage > 0
                                                    ? options.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : options
                                            ).map((option, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell width={400} align="left"> { option.attributes.given_name } </TableCell>
                                                            <TableCell width={400} align="left"> { option.attributes.email } </TableCell>
                                                            <TableCell align="right" style={{paddingRight:"34px"}}>
                                                                <IconButton onClick={() => onClickDetails(option.id)} aria-label="zoomIn">
                                                                    <ZoomIn />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            )
                                        )
                                        :
                                        (
                                            <TableRow>
                                                <TableCell align="center" colSpan={6}>
                                                    <h5>No Results Found</h5>
                                                </TableCell>
                                            </TableRow>
                                        )
                                }
                            </TableBody>
                        )
                    }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'Rows per Page',
                                    },
                                    native: true,
                                }}
                                page={page}
                                count={options.length}
                                rowsPerPage={rowsPerPage}
                                onChangePage={handleChangePage}
                                ActionsComponent={TableActions}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
    )
}