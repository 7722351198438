import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import {FrontendConfiguration} from "@common/configuration";
import {OwnerSingleLoginApp} from "./components/OwnerSingleLoginApp/OwnerSingleLoginApp";
import "bootstrap/dist/css/bootstrap.css";
import {ImplicitFlow, ThemeProvider} from "@vacasa/react-components-lib";
import theme from "@vacasa/react-components-lib/lib/themes/default";
if (FrontendConfiguration.environment() === FrontendConfiguration.LOCAL) {
    ReactDOM.render(
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <OwnerSingleLoginApp />
            </ThemeProvider>
        </React.StrictMode>,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(
        <React.StrictMode>
            <ImplicitFlow
                clientId={FrontendConfiguration.idpClientId()}
                redirectUrl={FrontendConfiguration.idpRedirectUri()}
                audience={FrontendConfiguration.idpManagementServiceConfig().url}
                scopes="owner-support:read owner-support:update"
                directoryHint="onelogin"
                env={FrontendConfiguration.environment()}
            >
                <ThemeProvider theme={theme}>
                    <OwnerSingleLoginApp />
                </ThemeProvider>
            </ImplicitFlow>
        </React.StrictMode>,
        document.getElementById("root")
    );
}
