import React, {useEffect} from "react";
import {Grid, Divider, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress} from "@material-ui/core";
import {Block, CheckCircleOutline} from "@material-ui/icons";
import {Connect, Idp} from "@common/entity";
import {IdpManagementApiService} from "@common/api-service";
import * as _ from "lodash";
import baseStyles from "../../../../styles/base.module.scss";
import styles from "./OwnerInfo.module.scss";
import {AlertMessage} from "@vacasa/react-components-lib";
import {Button as VacasaButton} from "@vacasa/react-components-lib";
import {getAppliedBy} from "../../../utils/loggedUser";

interface OwnerInfoProps {
    selectedOwner: Idp.User;
    setSidebarState: Function;
    setSelectedOwner: Function;
    refreshOwner: number;
    setRefreshOwner: Function;
}
type EnhancedLogin = Connect.Login & {is_active?: boolean; idp_user_id?: string};

const idpManagementApiService = new IdpManagementApiService();

export const OwnerInfo: React.FunctionComponent<OwnerInfoProps> = ({
    selectedOwner,
    setSidebarState,
    refreshOwner,
    setSelectedOwner,
    setRefreshOwner,
}) => {
    const [rows, setRows] = React.useState<Array<EnhancedLogin>>([]);
    const [searching, setSearching] = React.useState(false);

    useEffect(() => {
        (async () => {
            if (_.isNil(selectedOwner)) {
                return;
            }
            try {
                setSearching(true);
                const idpUser = await idpManagementApiService.getIdpUsersByEmail(selectedOwner.attributes.email);

                setSelectedOwner(idpUser);

                const contacts = idpUser?.attributes?.contact_ids;

                if (contacts?.length > 0) {
                    const loginsResult = await idpManagementApiService.getConnectLoginsByContactsIds(contacts);

                    if (loginsResult.length > 0) {
                        setRows(loginsResult);

                        const idpResult: Array<Idp.User> = await Promise.all(
                            loginsResult.map((x) => idpManagementApiService.getIdpUsersByEmail(x.attributes.email_address))
                        );

                        const enhancedUsersResult: Array<EnhancedLogin> = [...loginsResult];

                        for (let enhanced of enhancedUsersResult) {
                            const findResult = idpResult.find(
                                (x) =>
                                    x?.attributes?.email?.toLocaleLowerCase().trim() ===
                                    enhanced.attributes?.email_address?.toLocaleLowerCase().trim()
                            );

                            if (!_.isNil(findResult)) {
                                enhanced.is_active = findResult.attributes.is_active;
                                enhanced.idp_user_id = findResult.id;
                            }
                        }

                        setRows(enhancedUsersResult);
                    } else {
                        setRows([]);
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setSearching(false);
            }
        })();
    }, [refreshOwner]);

    const onUnlink = async (row: EnhancedLogin) => {
        try {
            setSearching(true);

            if (_.isNil(row.idp_user_id)) {
                throw Error(`${row.attributes.email_address} is not a Idp user`);
            }
            await idpManagementApiService.unlinkUsers({
                master_idp_user_id: selectedOwner.id,
                applied_by: getAppliedBy(),
                linked_users: [
                    {
                        contact_id: row.attributes.contact_id,
                        idp_user_id: row.idp_user_id,
                        state: true,
                        applied_by: getAppliedBy(),
                    },
                ],
            });
        } catch (e) {
            console.error(e);
            alert(e?.message ?? "error unlinking user");
        } finally {
            setSearching(false);
            setRefreshOwner(+new Date());
        }
    };

    const RenderUsersLinkedTable = () => {
        if (selectedOwner == null) {
            return null;
        }

        if (selectedOwner.attributes.contact_ids.length === 0) {
            return <AlertMessage text="No Linked Logins" type="info"></AlertMessage>;
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searching ? (
                            <TableRow>
                                <TableCell align="center" colSpan={3}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            rows
                                .filter(
                                    (x) =>
                                        x.attributes.email_address?.trim()?.toLocaleLowerCase() !==
                                        selectedOwner.attributes.email?.trim()?.toLocaleLowerCase()
                                )
                                .map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="left">
                                            {row.attributes.first_name} {row.attributes.last_name}
                                        </TableCell>
                                        <TableCell align="left">{row.attributes.email_address}</TableCell>
                                        <TableCell align="left">
                                            {row.is_active ? (
                                                <div>
                                                    <CheckCircleOutline style={{color: "green"}} /> &nbsp;
                                                    <span>Kept as a guest</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Block color={"error"} /> &nbsp;
                                                    <span>Disabled</span>
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    onUnlink(row);
                                                }}
                                                disabled={searching}
                                                style={{
                                                    textTransform: "none",
                                                }}
                                            >
                                                Unlink
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const RenderUserInfo = () => {
        if (selectedOwner == null) {
            return null;
        }
        return (
            <div>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <h5 className={baseStyles.titleColor}>Owner Master Login</h5>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <span className={baseStyles.fontBold}>Name :</span>{" "}
                        <span>
                            {selectedOwner.attributes.given_name} {selectedOwner.attributes.family_name}
                        </span>
                    </Grid>
                    <Grid item xs={3}>
                        <span className={baseStyles.fontBold}>Email :</span> <span>{selectedOwner.attributes.email}</span>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Divider />
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <h5 style={{marginTop: "7px"}} className={baseStyles.titleColor}>
                            Linked Logins
                        </h5>
                    </Grid>
                    <Grid item xs={3}>
                        <VacasaButton
                            variant="info"
                            onClick={() => {
                                setSidebarState(true);
                            }}
                            disabled={searching}
                        >
                            Link Login
                        </VacasaButton>
                    </Grid>
                </Grid>
            </div>
        );
    };
    return (
        <div className={styles.innerMargin}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <RenderUserInfo />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={8}>
                            <RenderUsersLinkedTable />
                        </Grid>

                        <Grid item xs={4} style={{textAlign: "right"}}></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
