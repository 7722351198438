import React, {ReactNode, useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import {DialogActions, Divider} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import "./ErrorModal.scss";
import {Button, Icon} from "@vacasa/react-components-lib";
import * as _ from "lodash";

interface ErrorModalProps {
    title: ReactNode;
    body: ReactNode;
    setBody: (value: ReactNode) => void;
}

export const ErrorModal = (props: ErrorModalProps) => {
    const {setBody, title, body} = props;
    const [show, setShow] = useState(false);
    const [internalBody, setInternalBody] = useState<ReactNode>(null);

    useEffect(() => {
        if (body !== null && body !== "") {
            setInternalBody(body);
            setShow(true);
        } else {
            setShow(false);
            _.debounce(() => setInternalBody(null), 200);
        }
    }, [body]);

    const onClose = () => {
        setBody(null);
    };

    return (
        <Dialog className="dialog-container" open={show} onClose={onClose} maxWidth="sm" fullWidth>
            <div className="modal-header">
                <div className="modal-title">{title}</div>
                {onClose && (
                    <IconButton className="close-button" aria-label="close" onClick={onClose}>
                        <CancelIcon style={{color: "#A9A7A5"}} />
                    </IconButton>
                )}
            </div>
            <Divider />
            <DialogContent>
                <div className="modal-body">
                    <Icon.AlertOctagon height={48} width={48} className="error-modal-icon" />
                </div>
                <div className="modal-body">{internalBody}</div>
            </DialogContent>
            <DialogActions>
                <Button variant="info" onClick={onClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
