import React, {ReactNode} from "react";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import {DialogActions, Divider} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import "./ConfirmationModal.scss";
import {Button, Icon} from "@vacasa/react-components-lib";

interface ConfirmationModalProps {
    open: boolean;
    title: ReactNode;
    children: ReactNode;
    onConfirm: () => Promise<void>;
    setOpen: (value: boolean) => void;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
    const {open, title, children} = props;
    const {setOpen, onConfirm} = props;

    const onClose = () => {
        setOpen(false);
    };

    const onConfirmClick = async () => {
        onClose();
        onConfirm();
    };

    return (
        <Dialog className="dialog-container" open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <div className="modal-header">
                <div className="modal-title">{title}</div>
                {onClose && (
                    <IconButton className="close-button" aria-label="close" onClick={onClose}>
                        <CancelIcon style={{color: "#A9A7A5"}} />
                    </IconButton>
                )}
            </div>
            <Divider />
            <DialogContent>
                <div className="modal-body">
                    <Icon.AlertTriangle height={48} width={48} className="confirmation-modal-icon" />
                </div>
                <div className="modal-body">{children}</div>
            </DialogContent>
            <DialogActions>
                <Button variant="info" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="secondary" onClick={onConfirmClick}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
