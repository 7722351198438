import _ from 'lodash';
import { DateTime } from "luxon";
import React, {useEffect, useState} from "react";
import {
    Accordion, AccordionDetails, AccordionSummary,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import {IdpManagementApiService} from "@common/api-service";
import baseStyles from "../../../../styles/base.module.scss";

const idpManagementApiService = new IdpManagementApiService();

export const OwnerChangeLog = ({ selectedOwner, refreshOwner }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [changeLogs, setChangeLogs] = useState([]);

    useEffect(() => {
        getChangeLogs().then(r => {
            setIsLoading(false);
        });
    },[refreshOwner]);

    const getChangeLogs = async () => {
        let logs = await idpManagementApiService.getChangeLogs(selectedOwner.id)

        if (_.isNil(logs)) {
            logs = [];
        }

        const logsFormatted = logs.map((log) => {
            return {
                id: log.id,
                username: log.attributes.description.substring(0, log.attributes.description.indexOf(' ')),
                description: log.attributes.description.substring(log.attributes.description.indexOf(' ') + 1),
                date: getPortlandDate(log.attributes.created_at),
                applied_by: log.attributes.applied_by,
            }
        });
        setChangeLogs(logsFormatted);
    }

    const getPortlandDate = (created_at: string): string => {
        const date = DateTime.fromISO(created_at).setZone("America/Los_angeles");
        return date.toFormat("MMM, d, yyyy, h:mm a.").toLowerCase();
    }

    return (
        <Accordion
            style={{
                boxShadow: "none",
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
            >
                <Grid item xs={12}>
                    <div>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <h5 className={baseStyles.titleColor}>Change logs</h5>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Description</TableCell>
                                        <TableCell align="left">Applied By</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        changeLogs.map((row, index) => {
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell align="left"> { row.date } </TableCell>
                                                    <TableCell align="left">
                                                        <span style={{color: '#42a5f5'}} > { row.username } </span>
                                                        <span> { row.description } </span>
                                                    </TableCell>
                                                    <TableCell align="left"> { row.applied_by } </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};