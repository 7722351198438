import jwtDecode from "jwt-decode";

interface AccessTokenClaimsType {
    created_at: string;
    email: string;
    name: string;
}

const keys = {
    accessToken: "access_token",
    idToken: "idToken",
    nonce: "nonce",
    state: "state"
};

export function getAppliedBy(): string {
    const idToken = localStorage.getItem(keys.idToken) || "";
    const decoded: AccessTokenClaimsType = jwtDecode(idToken);

    return "@" + decoded.email.split('@')[0];
}